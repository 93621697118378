import { badgeRecipe } from './badge';
import { buttonRecipe } from './button';
import { codeRecipe } from './code';
import { containerRecipe } from './container';
import { headingRecipe } from './heading';
import { inputRecipe } from './input';
import { inputAddonRecipe } from './input-addon';
import { kbdRecipe } from './kbd';
import { linkRecipe } from './link';
import { markRecipe } from './mark';
import { separatorRecipe } from './separator';
import { skeletonRecipe } from './skeleton';
import { skipNavLinkRecipe } from './skip-nav-link';
import { spinnerRecipe } from './spinner';
import { textareaRecipe } from './textarea';
import { iconRecipe } from './icon';
import { checkmarkRecipe } from './checkmark';
import { radiomarkRecipe } from './radiomark';

export const recipes = {
	badge: badgeRecipe,
	button: buttonRecipe,
	code: codeRecipe,
	container: containerRecipe,
	heading: headingRecipe,
	input: inputRecipe,
	inputAddon: inputAddonRecipe,
	kbd: kbdRecipe,
	link: linkRecipe,
	mark: markRecipe,
	separator: separatorRecipe,
	skeleton: skeletonRecipe,
	skipNavLink: skipNavLinkRecipe,
	spinner: spinnerRecipe,
	textarea: textareaRecipe,
	icon: iconRecipe,
	checkmark: checkmarkRecipe,
	radiomark: radiomarkRecipe,
};
