import React, { createContext, useContext } from 'react';
import { User } from '@shared/zod-schemas';

export const AuthContext = createContext<User | undefined>(undefined);

export const useUser = () => useContext(AuthContext);

export function AuthContextProvider({ children, user }: { children: React.ReactNode; user?: User }) {
	return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}
