import { defineTokens } from '@chakra-ui/react';

export const borders = defineTokens.borders({
	xs: {
		value: '0.5px solid',
	},
	sm: {
		value: '1px solid',
	},
	md: {
		value: '2px solid',
	},
	lg: {
		value: '4px solid',
	},
	xl: {
		value: '8px solid',
	},
});
