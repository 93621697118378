import { accordionSlotRecipe } from './accordion';
import { actionBarSlotRecipe } from './action-bar';
import { alertSlotRecipe } from './alert';
import { avatarSlotRecipe } from './avatar';
import { blockquoteSlotRecipe } from './blockquote';
import { breadcrumbSlotRecipe } from './breadcrumb';
import { cardSlotRecipe } from './card';
import { checkboxSlotRecipe } from './checkbox';
import { checkboxCardSlotRecipe } from './checkbox-card';
import { collapsibleSlotRecipe } from './collapsible';
import { dataListSlotRecipe } from './data-list';
import { dialogSlotRecipe } from './dialog';
import { drawerSlotRecipe } from './drawer';
import { editableSlotRecipe } from './editable';
import { emptyStateSlotRecipe } from './empty-state';
import { fieldSlotRecipe } from './field';
import { fieldsetSlotRecipe } from './fieldset';
import { fileUploadSlotRecipe } from './file-upload';
import { hoverCardSlotRecipe } from './hover-card';
import { listSlotRecipe } from './list';
import { menuSlotRecipe } from './menu';
import { nativeSelectSlotRecipe } from './native-select';
import { numberInputSlotRecipe } from './number-input';
import { pinInputSlotRecipe } from './pin-input';
import { popoverSlotRecipe } from './popover';
import { progressSlotRecipe } from './progress';
import { progressCircleSlotRecipe } from './progress-circle';
import { radioCardSlotRecipe } from './radio-card';
import { radioGroupSlotRecipe } from './radio-group';
import { ratingGroupSlotRecipe } from './rating-group';
import { segmentGroupSlotRecipe } from './segment-group';
import { selectSlotRecipe } from './select';
import { sliderSlotRecipe } from './slider';
import { statSlotRecipe } from './stat';
import { stepsSlotRecipe } from './steps';
import { switchSlotRecipe } from './switch';
import { tableSlotRecipe } from './table';
import { tabsSlotRecipe } from './tabs';
import { tagSlotRecipe } from './tag';
import { toastSlotRecipe } from './toast';
import { tooltipSlotRecipe } from './tooltip';
import { statusSlotRecipe } from './status';
import { timelineSlotRecipe } from './timeline';

export const slotRecipes = {
	accordion: accordionSlotRecipe,
	actionBar: actionBarSlotRecipe,
	alert: alertSlotRecipe,
	avatar: avatarSlotRecipe,
	blockquote: blockquoteSlotRecipe,
	breadcrumb: breadcrumbSlotRecipe,
	card: cardSlotRecipe,
	checkbox: checkboxSlotRecipe,
	checkboxCard: checkboxCardSlotRecipe,
	collapsible: collapsibleSlotRecipe,
	dataList: dataListSlotRecipe,
	dialog: dialogSlotRecipe,
	drawer: drawerSlotRecipe,
	editable: editableSlotRecipe,
	emptyState: emptyStateSlotRecipe,
	field: fieldSlotRecipe,
	fieldset: fieldsetSlotRecipe,
	fileUpload: fileUploadSlotRecipe,
	hoverCard: hoverCardSlotRecipe,
	list: listSlotRecipe,
	menu: menuSlotRecipe,
	nativeSelect: nativeSelectSlotRecipe,
	numberInput: numberInputSlotRecipe,
	pinInput: pinInputSlotRecipe,
	popover: popoverSlotRecipe,
	progress: progressSlotRecipe,
	progressCircle: progressCircleSlotRecipe,
	radioCard: radioCardSlotRecipe,
	radioGroup: radioGroupSlotRecipe,
	ratingGroup: ratingGroupSlotRecipe,
	segmentGroup: segmentGroupSlotRecipe,
	select: selectSlotRecipe,
	slider: sliderSlotRecipe,
	stat: statSlotRecipe,
	steps: stepsSlotRecipe,
	switch: switchSlotRecipe,
	table: tableSlotRecipe,
	tabs: tabsSlotRecipe,
	tag: tagSlotRecipe,
	toast: toastSlotRecipe,
	tooltip: tooltipSlotRecipe,
	status: statusSlotRecipe,
	timeline: timelineSlotRecipe,
};
