'use client';

import { useState } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

// TODO DATEPICKER
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerLocale } from 'react-datepicker';
import { cs } from 'date-fns/locale/cs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { User } from '@shared/zod-schemas';

import { Provider } from '@/components/ui/provider';
import { AuthContextProvider } from '@/supabase/AuthContext';
import { errorToast } from '@/utils/toast';

registerLocale('cs', cs);

export function ClientProviders({ children, user }: { children: React.ReactNode; user?: User }) {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				queryCache: new QueryCache({
					onError: (error) => {
						errorToast('Nastala chyba');
						console.error('Error in query cache', JSON.stringify(error));
					},
				}),
				defaultOptions: {
					queries: {
						// TODO REACT-QUERY tohle prestalo byt v typech v react-query v5
						// prijde mne ale ze to funguje spravne
						// https://github.com/TanStack/query/issues/8218
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						getNextPageParam: (lastPage: any) => lastPage?.nextCursor ?? undefined,
						refetchOnWindowFocus: false,
					},
				},
			}),
	);

	return (
		<AuthContextProvider user={user}>
			<Provider>
				<QueryClientProvider client={queryClient}>
					{children}
					{process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
				</QueryClientProvider>
			</Provider>
		</AuthContextProvider>
	);
}
