import { defineTokens } from '@chakra-ui/react';

export const cursor = defineTokens.cursor({
	button: {
		value: 'pointer',
	},
	checkbox: {
		value: 'default',
	},
	disabled: {
		value: 'not-allowed',
	},
	menuitem: {
		value: 'default',
	},
	option: {
		value: 'default',
	},
	radio: {
		value: 'default',
	},
	slider: {
		value: 'default',
	},
	switch: {
		value: 'pointer',
	},
});
