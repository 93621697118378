import { Gabarito, Inter } from 'next/font/google';
import { createSystem, defaultBaseConfig, defineConfig, mergeConfigs, defineTokens } from '@chakra-ui/react';
import { animationStyles } from './animation-styles';
import { breakpoints } from './breakpoints';
import { globalCss } from './global-css';
import { keyframes } from './keyframes';
import { layerStyles } from './layer-styles';
import { recipes } from './recipes';
import { semanticTokens } from './semantic-tokens';
import { slotRecipes } from './slot-recipes';
import { textStyles } from './text-styles';
import { tokens } from './tokens';

const inter = Inter({
	weight: ['400', '500', '600', '700', '800'],
	subsets: ['latin'],
	display: 'swap',
});

const gabarito = Gabarito({
	weight: ['400', '500', '600', '700'],
	subsets: ['latin'],
	display: 'swap',
});

const customTokens = defineTokens({
	fonts: {
		heading: { value: gabarito.style.fontFamily },
		body: { value: inter.style.fontFamily },
		gabarito: { value: gabarito.style.fontFamily },
	},
	colors: {
		primaryDark: {
			100: { value: 'hsl(158, 81%, 31%)' },
			200: { value: 'hsl(158, 81%, 27%)' },
		},
		primaryLight: { value: 'hsl(123, 57%, 64%)' },
		secondaryDark: { value: 'hsl(40, 83%, 58%)' },
		secondaryLight: { value: 'hsl(42, 93%, 78%)' },

		// Neutral shades
		neutralDark: { value: 'hsl(156, 20%, 5%)' },
		neutralDarkAlpha: { value: 'hsla(156, 20%, 5%, 0.8)' },
		neutralLight: { value: 'hsl(159, 50%, 95%)' },
		neutralLightAlpha: { value: 'hsla(159, 50%, 95%, 0.8)' },

		// Primary shades with alpha
		primaryDarkAlpha: {
			10: { value: 'hsla(158, 81%, 31%, 0.1)' },
			20: { value: 'hsla(158, 81%, 31%, 0.2)' },
			30: { value: 'hsla(158, 81%, 31%, 0.3)' },
			40: { value: 'hsla(158, 81%, 31%, 0.4)' },
			50: { value: 'hsla(158, 81%, 31%, 0.5)' },
			60: { value: 'hsla(158, 81%, 31%, 0.6)' },
			70: { value: 'hsla(158, 81%, 31%, 0.7)' },
			80: { value: 'hsla(158, 81%, 31%, 0.8)' },
			90: { value: 'hsla(158, 81%, 31%, 0.9)' },
		},

		// Primary Light shades with alpha
		primaryLightAlpha: {
			10: { value: 'hsla(123, 57%, 64%, 0.1)' },
			20: { value: 'hsla(123, 57%, 64%, 0.2)' },
			30: { value: 'hsla(123, 57%, 64%, 0.3)' },
			40: { value: 'hsla(123, 57%, 64%, 0.4)' },
			50: { value: 'hsla(123, 57%, 64%, 0.5)' },
			60: { value: 'hsla(123, 57%, 64%, 0.6)' },
			70: { value: 'hsla(123, 57%, 64%, 0.7)' },
			80: { value: 'hsla(123, 57%, 64%, 0.8)' },
			90: { value: 'hsla(123, 57%, 64%, 0.9)' },
		},

		// Secondary shades with alpha
		secondaryAlpha: {
			10: { value: 'hsla(40, 30%, 58%, 0.1)' },
			20: { value: 'hsla(40, 30%, 58%, 0.2)' },
			30: { value: 'hsla(40, 30%, 58%, 0.3)' },
			40: { value: 'hsla(40, 30%, 58%, 0.4)' },
			50: { value: 'hsla(40, 30%, 58%, 0.5)' },
			60: { value: 'hsla(40, 30%, 58%, 0.6)' },
			70: { value: 'hsla(40, 30%, 58%, 0.7)' },
			80: { value: 'hsla(40, 30%, 58%, 0.8)' },
			90: { value: 'hsla(40, 30%, 58%, 0.9)' },
		},

		// Secondary Light shades with alpha
		secondaryLightAlpha: {
			10: { value: 'hsla(42, 93%, 78%, 0.1)' },
			20: { value: 'hsla(42, 93%, 78%, 0.2)' },
			30: { value: 'hsla(42, 93%, 78%, 0.3)' },
			40: { value: 'hsla(42, 93%, 78%, 0.4)' },
			50: { value: 'hsla(42, 93%, 78%, 0.5)' },
			60: { value: 'hsla(42, 93%, 78%, 0.6)' },
			70: { value: 'hsla(42, 93%, 78%, 0.7)' },
			80: { value: 'hsla(42, 93%, 78%, 0.8)' },
			90: { value: 'hsla(42, 93%, 78%, 0.9)' },
		},
	},
});

// Vytvoření systému a přidání dalších konfiguračních možností
const customConfig = defineConfig({
	theme: {
		semanticTokens: {
			colors: {
				bodyText: { value: { base: '{colors.gray.600}', _dark: '{colors.gray.300}' } },
				bgRed: { value: { base: '{colors.red.50}', _dark: '{colors.red.800}' } },
				bgBlue: { value: { base: '{colors.blue.50}', _dark: '{colors.blue.800}' } },
				bgGreen: { value: { base: '{colors.green.50}', _dark: '{colors.green.800}' } },
				bgYellow: { value: { base: '{colors.yellow.50}', _dark: '{colors.yellow.800}' } },
				bgGray: { value: { base: '{colors.gray.200}', _dark: '{colors.gray.800}' } },
				activitiRed: { value: { base: '{colors.red.500}', _dark: '{colors.red.700}' } },
				activitiGray: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.700}' } },
				activitiGreen: { value: { base: '{colors.green.500}', _dark: '{colors.green.700}' } },
				activitiBlue: { value: { base: '{colors.blue.500}', _dark: '{colors.blue.700}' } },
				activitiLine: { value: { base: '{colors.gray.300}', _dark: '{colors.blue.500}' } },
				alertRed: { value: { base: '{colors.red.500}', _dark: '{colors.red.500}' } },
				absenceMoreRed: { value: { base: '{colors.red.800}', _dark: '{colors.red.200}' } },
				card: { value: { base: '{colors.white}', _dark: '{colors.gray.700}' } },
				borderInput: { value: { base: '{colors.gray.200}', _dark: '{colors.gray.600}' } },
				borderFocusInput: { value: { base: '#3182ce', _dark: '#63b3ed' } },
				bgMain: { value: { base: '{colors.gray.50}', _dark: '{colors.gray.800}' } },
				bgMenu: { value: { base: '{colors.white}', _dark: '{colors.gray.900}' } },
				bgCard: { value: { base: '{colors.white}', _dark: '{colors.gray.700}' } },
				bgAvatar: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.600}' } },
				avatarText: { value: { base: '{colors.gray.700}', _dark: '{colors.gray.400}' } },
				userJobTitle: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.200}' } },
				bgInput: { value: { base: '{colors.white}', _dark: '{colors.gray.600}' } },
				bgInputTableFilter: { value: { base: '{colors.gray.50}', _dark: '{colors.gray.600}' } },
				bgDisabledBtn: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.600}' } },
				bgTooltip: { value: { base: '{colors.gray.600}', _dark: '{colors.gray.500}' } },
				textTooltip: { value: { base: '{colors.white}', _dark: '{colors.gray.600}' } },
				textInput: { value: { base: '{colors.gray.600}', _dark: '{colors.white}' } },
				datePickerTextColor: { value: { base: '{colors.gray.700}', _dark: '{colors.gray.300}' } },
				datePickerTextHoverColor: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.500}' } },
				datePickerSelectedTextColor: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.300}' } },
				datePickerSelectedBgColor: { value: { base: '{colors.green.300}', _dark: '#0F8F60' } },
				datePickerSelectedBgHoverColor: { value: { base: '{colors.green.200}', _dark: '#084E35' } },
				datePickerSelect: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.600}' } },
				placehoderInput: { value: { base: '{colors.gray.400}', _dark: '{colors.gray.500}' } },
				borderMenu: { value: { base: '{colors.gray.200}', _dark: '{colors.gray.700}' } },
				borderDivider: { value: { base: '{colors.gray.200}', _dark: '{colors.gray.600}' } },
				colorSecondary: { value: { base: '{colors.gray.800}', _dark: '{colors.gray.200}' } },
				titleColor: { value: { base: '{colors.gray.700}', _dark: '{colors.gray.200}' } },
				thTextColor: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.300}' } },
				subTitleColor: { value: { base: '{colors.gray.600}', _dark: '{colors.gray.300}' } },
				descriptionTextColor: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.300}' } },
				navButtonTextColor: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.300}' } },
				navSubmenuBgColor: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.600}' } },
				primaryAccentAlpha: {
					value: { base: '{colors.primaryDarkAlpha.10}', _dark: '{colors.primaryLightAlpha.10}' },
				},
				primaryText: { value: { base: '{colors.primaryDark.100}', _dark: '{colors.primaryLight}' } },
				neutralAccent: { value: { base: '{colors.gray.50}', _dark: '{colors.whiteAlpha.100}' } },
				thHeadingColor: { value: { base: '{colors.gray.400}', _dark: '{colors.gray.500}' } },
				thHoverTextColor: { value: { base: '{colors.black}', _dark: '{colors.white}' } },
				textGreen: { value: { base: '{colors.green.500}', _dark: '{colors.green.500}' } },
				textRed: { value: { base: '{colors.red.500}', _dark: '{colors.red.500}' } },
				textOrange: { value: { base: '{colors.orange.500}', _dark: '{colors.orange.500}' } },
				thHoverBgColor: { value: { base: '{colors.gray.100}', _dark: '{colors.whiteAlpha.100}' } },
				menuHoverBgColor: { value: { base: '{colors.gray.50}', _dark: '{colors.whiteAlpha.100}' } },
			},
		},
		tokens: customTokens,
	},
});

const themeConfig = defineConfig({
	preflight: true,
	cssVarsPrefix: 'chakra',
	cssVarsRoot: ':where(:root, :host)',
	globalCss,
	theme: {
		breakpoints,
		keyframes,
		tokens,
		semanticTokens,
		recipes,
		slotRecipes,
		textStyles,
		layerStyles,
		animationStyles,
	},
});

export const customTheme = createSystem(defaultBaseConfig, mergeConfigs(themeConfig, customConfig));
