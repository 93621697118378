'use client';

import { forwardRef } from 'react';
import type { GroupProps, SlotRecipeProps } from '@chakra-ui/react';
import { Avatar as ChakraAvatar, Group } from '@chakra-ui/react';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export interface AvatarProps extends ChakraAvatar.RootProps {
	name?: string;
	src?: string;
	srcSet?: string;
	loading?: ImageProps['loading'];
	icon?: React.ReactElement;
	fallback?: React.ReactNode;
}
interface AvatarFallbackProps extends ChakraAvatar.FallbackProps {
	name?: string;
	icon?: React.ReactElement;
}

// eslint-disable-next-line react/display-name
const AvatarFallback = forwardRef<HTMLDivElement, AvatarFallbackProps>((props, ref) => {
	const { name, icon, children, ...rest } = props;
	return (
		<ChakraAvatar.Fallback ref={ref} {...rest}>
			{children}
			{/* eslint-disable-next-line no-use-before-define */}
			{name != null && children == null && <>{getInitials(name)}</>}
			{name == null && children == null && <ChakraAvatar.Icon asChild={!!icon}>{icon}</ChakraAvatar.Icon>}
		</ChakraAvatar.Fallback>
	);
});

// eslint-disable-next-line react/display-name
export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
	const { name, src, srcSet, loading, icon, fallback, children, ...rest } = props;
	return (
		<ChakraAvatar.Root ref={ref} {...rest}>
			<AvatarFallback name={name} icon={icon}>
				{fallback}
			</AvatarFallback>
			<ChakraAvatar.Image src={src} srcSet={srcSet} loading={loading} />
			{children}
		</ChakraAvatar.Root>
	);
});

function getInitials(name: string) {
	const names = name.trim().split(' ');
	const firstName = names[0] != null ? names[0] : '';
	const lastName = names.length > 1 ? names[names.length - 1] : '';
	return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0);
}

interface AvatarGroupProps extends GroupProps, SlotRecipeProps<'avatar'> {}

// eslint-disable-next-line react/display-name
export const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>((props, ref) => {
	const { size, variant, borderless, ...rest } = props;
	return (
		<ChakraAvatar.PropsProvider value={{ size, variant, borderless }}>
			<Group gap="0" spaceX="-3" ref={ref} {...rest} />
		</ChakraAvatar.PropsProvider>
	);
});
